<template>
	<header class="c-header">
		<div class="uc-content">
			<router-link class="logo" to="">
				<img src="@/assets/images/logo.png" alt="">
			</router-link>
			<ul class="menu">
				<li :class="indexNumber==4?'on':''">
				  <router-link class="item" to="/">首页</router-link>
				</li>
			</ul>

		</div>
	</header>
</template>
<script>
	export default {
		name: 'ucHead',
		components: {},
		props: {
			indexNumber: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {}
		},
		mounted() {
			// console.log(this.indexNumber)
		},
		methods: {
      async logout() {
        this.$confirm('确定注销并退出系统吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('FedLogOut').then(() => {
            location.href = '/';
          })
        }).catch(() => {});
      }
    },
	}
</script>

<style scoped>

.c-header .user-menu{

  top:80px;
  left: -15px;

}
.c-header .sub-menu{
  top:100px
}

</style>
