<template>
  <div>
    <ucHead :indexNumber="4"></ucHead>
    <section class="uc-verification-1" style="height: auto;padding-bottom: 50px">
      <div class="uc-content">
        <h2>企业贷</h2>
        <div class="box">
          <div class="hd" style="padding-left: 150px">

            <el-form ref="form" :model="form" :rules="rules" label-width="180px">
              <el-form-item label="企业名称" required prop="companyName">
                <el-input v-model="form.companyName" placeholder="请输入企业名称"></el-input>
              </el-form-item>

              <el-form-item label="统一社会信用代码" required  prop="creditCode">
                <el-input v-model="form.creditCode" placeholder="请输入统一社会信用代码"></el-input>
              </el-form-item>

              <el-form-item label="营业执照" required prop="businessLicensePath">
                <image-upload-free :limit="1" v-model="form.businessLicensePath"></image-upload-free>
              </el-form-item>


              <el-form-item label="近期投标项目截图" required prop="recentBidPath">
                <image-upload-free :limit="1" v-model="form.recentBidPath"></image-upload-free>
              </el-form-item>

              <el-form-item label="法人征信报告" required prop="creditReportPath">
                <image-upload-free :limit="1" :file-size="20" v-model="form.creditReportPath"></image-upload-free>
              </el-form-item>

              <el-form-item label="21年开票金额" required prop="tax21">
                <el-input v-model="form.tax21" placeholder="请输入21年开票金额"></el-input>
              </el-form-item>

              <el-form-item label="22年开票金额" required prop="tax22">
                <el-input v-model="form.tax22" placeholder="请输入22年开票金额"></el-input>
              </el-form-item>

              <el-form-item label="近期开票总额（12个月）" required prop="totalInvoiceLastMonth12">
                <el-input v-model="form.totalInvoiceLastMonth12" placeholder="请输入近期开票总额（12个月）"></el-input>
              </el-form-item>

              <el-form-item label="目前在用的贷款名称" required prop="nowLoanName">
                <el-input v-model="form.nowLoanName" placeholder="请输入目前在用的贷款名称"></el-input>
              </el-form-item>

              <el-form-item label="目前在用的贷款额度" required prop="nowLoanAmount">
                <el-input v-model="form.nowLoanAmount" placeholder="请输入目前在用的贷款额度"></el-input>
              </el-form-item>

              <el-form-item label="当前是否有案件" required prop="nowLawCase">
                <el-radio-group v-model="form.nowLawCase">
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="0">无</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="当前是否有逾期" required prop="nowExceed">
                <el-radio-group v-model="form.nowExceed">
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="0">无</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="历史逾期是否超过连续两个月未还" required prop="historyExceedTwoMonth">
                <el-radio-group v-model="form.historyExceedTwoMonth">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="公司是否成立满两年" required prop="buildYear2">
                <el-radio-group v-model="form.buildYear2" >
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="法人6个月内是否有变更" required prop="legalPersonChangeSixMonth">
                <el-radio-group v-model="form.legalPersonChangeSixMonth" >
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="0">无</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="法人年龄是否超过65周岁" required prop="legalPersonAge65">
                <el-radio-group v-model="form.legalPersonAge65" >
                  <el-radio :label="1">有</el-radio>
                  <el-radio :label="0">无</el-radio>
                </el-radio-group>
              </el-form-item>


              <el-form-item label="联系人" required prop="contactName">
                <el-input v-model="form.contactName" placeholder="请输入联系人"></el-input>
              </el-form-item>


              <el-form-item label="联系电话" required prop="contactPhone">
                <el-input v-model="form.contactPhone" placeholder="请输入联系电话"></el-input>
              </el-form-item>


              <el-form-item>
                <el-button type="primary" @click="onSubmit('form')"  v-loading="loading">立即提交</el-button>
              </el-form-item>
            </el-form>


          </div>

        </div>
      </div>
    </section>


    <ucFoot></ucFoot>
  </div>
</template>
<script>

import {apply} from "@/api/loan";
import ImageUpload from "@/components/ImageUpload/index.vue";
import ImageUploadFree from "@/components/ImageUploadFree/index.vue";

export default {
  name: 'loan',
  components: {ImageUpload,ImageUploadFree},
  data() {
    return {
      form: {},
      loading: false,
      code: "",

      rules:{
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" }
        ],
        creditCode: [
          { required: true, message: "请输入统一社会信用代码", trigger: "blur" }
        ],
        businessLicensePath: [
          { required: true, message: "请上传营业执照", trigger: "change" }
        ],
        tax21: [
          { required: true, message: "请输入21年开票金额", trigger: "blur" }
        ],
        tax22: [
          { required: true, message: "请输入22年开票纳金额", trigger: "blur" }
        ],
        nowLoanName: [
          { required: true, message: "请输入目前在用的贷款名称", trigger: "blur" }
        ],
        nowLoanAmount: [
          { required: true, message: "请输入目前在用的贷款额度", trigger: "blur" }
        ],
        nowLawCase: [
          { required: true, message: "请选择当前是否有案件", trigger: "change" }
        ],
        nowExceed: [
          { required: true, message: "请选择当前是否有逾期", trigger: "change" }
        ],
        historyExceedTwoMonth: [
          { required: true, message: "请选择历史逾期是否超过连续两个月未还", trigger: "change" }
        ],
        legalPersonChangeSixMonth: [
          { required: true, message: "请选择法人6个月内是否有变更", trigger: "change" }
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" }
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" }
        ],
        legalPersonAge65: [
          { required: true, message: "请选择法人年龄是否超过65周岁", trigger: "change" }
        ],
        buildYear2: [
          { required: true, message: "请选择公司是否成立满两年", trigger: "change" }
        ],
        totalInvoiceLastMonth12: [
          { required: true, message: "请输入近期开票总额（12个月）", trigger: "blur" }
        ],
        creditReportPath: [
          { required: true, message: "请上传征信报告", trigger: "change" }
        ],
        recentBidPath: [
          { required: true, message: "请上传近期投标项目", trigger: "change" }
        ],

      }
    }
  },
  mounted() {
  },
  methods: {

    onSubmit(formName) {


      this.$refs[formName].validate((valid) => {

        if (valid) {

          if (!this.loading) {

            let _this = this

            initGeetest4({
              captchaId: 'b67b32cc7a29cedd6aed1f4042149a0f',
              product: "bind",
              nativeButton: {
                width: "200px",
                height: "100px"
              }
            }, function (captcha) {
              // captcha为验证码实例
              // captcha.appendTo("#test");// 调用appendTo将验证码插入到页的某一个元素中，这个元素用户可以自定义
              captcha.onReady(function () {

                _this.loading = true

                //your code
              }).onSuccess(function () {
                //your code
                var result = captcha.getValidate();

                // let postdata = {
                //   'code': _this.code,
                //   'captchaOutput': result.captcha_output,
                //   'genTime': result.gen_time,
                //   'lotNumber': result.lot_number,
                //   'passToken': result.pass_token
                // }

                _this.form.lotNumber = result.lot_number;
                _this.form.captchaOutput = result.captcha_output;
                _this.form.passToken = result.pass_token;
                _this.form.genTime = result.gen_time;


                apply(_this.form).then(res => {
                  if (res.code == 200) {
                    _this.loading = false
                    _this.$message.success({type:'success',message:"提交成功！请等待审核！", onClose:function (){

                        console.log("ssss")

                        window.location.reload()


                      }})
                    // _this.$refs[formName].resetForm()

                  } else {
                    _this.$message.error("提交失败！")
                    _this.loading = false
                    return false
                  }
                  // console.log(res)
                }).catch(() => {

                  _this.loading = false

                })


              }).onError(function () {
                //your code
                _this.loading = false

              }).onClose(function () {
                _this.loading = false
              })
              captcha.showCaptcha();

            });
          }


        }


      })




    }


  },
}
</script>
